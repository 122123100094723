import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button, Layout, Alert } from 'antd'
/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import logo from '../img/chainalysis-logo.svg'
import { AuthenticationAPI } from '../apis'
import { useQueryParams } from './hooks'
import { ErrorMessage } from './styled/text'
import { LoginPagePattern } from './styled'

const { Content } = Layout

const ResetPassword = () => {
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [message, setMessage] = useState('Use the button below to get started')
  const queryParams = useQueryParams()

  const getNewPassword = () => {
    AuthenticationAPI.requestNewPassword(queryParams.token)
      .then(res => {
        setIsSuccess(true)
        setUserName(res.userName)
        setPassword(res.password)
        setMessage('Please use below credentials to login to your Chainalysis account.')
      })
      .catch(() => {
        setIsError(true)
        setMessage('Expired or invalid token! please try resetting your password again')
      })
  }

  return (
    <Layout style={{ backgroundColor: '#fff' }}>
      <Content
        style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          height: '100%',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <LoginPagePattern />
        <div css={{ flex: '1 1 61.8%', padding: '4rem', display: 'flex', flexDirection: 'column' }}>
          <div>
            <img src={logo} alt="Chainalysis" />
          </div>
          <h2>Password Reset</h2>
          {isSuccess ? (
            <div>
              <p>{message}</p>
              <p>
                <b>Username:&nbsp;</b>
                <span>{userName}</span>
              </p>
              <p>
                <b>Password:&nbsp;</b>
                <span>{password}</span>
              </p>
              <Alert
                message={`Please note: The password is not stored anywhere 
                  and can not be retrieved again after closing this page.`}
                type="warning"
                showIcon
              />
              <Button size="large" style={{ marginTop: '15px' }} type="primary">
                <Link to="/login">Back to Sign In</Link>
              </Button>
            </div>
          ) : (
            <div>
              {isError ? <ErrorMessage>{message}</ErrorMessage> : <p>{message}</p>}
              <Button type="primary" size="large" onClick={getNewPassword}>
                Get New Password
              </Button>
            </div>
          )}
        </div>
      </Content>
    </Layout>
  )
}

export default ResetPassword
