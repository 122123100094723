import { AxiosResponse } from 'axios'
import { makeUMRequest } from '../utils/request'
import {
  IOrganizationUpdateRequest,
  IOrganizationOIDCConnectionRequest,
  IOrganizationOIDCConnectionResponse,
  IOrganizationSAMLConnectionRequest,
  IOrganizationSAMLConnectionResponse,
} from '../utils/types'

class OrganizationAPI {
  static async getOrganization(orgId: number | null): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'GET',
      url: `orgadmin/organization/${orgId}`,
    })
    return res.data
  }

  static async updateOrganization(orgId: number | null, data: IOrganizationUpdateRequest): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'PUT',
      url: `orgadmin/organization/${orgId}`,
      data,
    })
    return res.data
  }

  static async createOIDCConnection(orgId: number | undefined, data: IOrganizationOIDCConnectionRequest): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'POST',
      url: `orgadmin/organization/${orgId}/oidcconnection`,
      data,
    })
    return res.data
  }

  static async getOIDCConnection(orgId: number): Promise<IOrganizationOIDCConnectionResponse> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'GET',
      url: `orgadmin/organization/${orgId}/oidcconnection`,
    })
    return res.data
  }

  static async createSAMLConnection(orgId: number | undefined, data: IOrganizationSAMLConnectionRequest): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'POST',
      url: `orgadmin/organization/${orgId}/samlconnection`,
      data,
    })
    return res.data
  }

  static async getSAMLConnection(orgId: number): Promise<IOrganizationSAMLConnectionResponse> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'GET',
      url: `orgadmin/organization/${orgId}/samlconnection`,
    })
    return res.data
  }
}

export default OrganizationAPI
