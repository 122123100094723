import styled from '@emotion/styled'
import LoginPatternSvg from '../../utils/customSvg'

// eslint-disable-next-line import/prefer-default-export
export const LoginPagePattern = styled.div`
  min-height: 100vh;
  flex: 1 1 38.2%;
  display: block;
  background-color: #293972;
  background-image: url('${LoginPatternSvg}');
`
