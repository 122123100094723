import React from 'react'
import { Spin } from 'antd'
import Container from './Container'

type LoaderProps = {
  description?: string
  container?: boolean
}

const Loader = ({ description, container }: LoaderProps) => (
  <div
    style={
      container
        ? { textAlign: 'center', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }
        : { textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }
    }
  >
    <Spin size="large" tip={description} />
  </div>
)

export default Loader
