import React, { useState, useEffect, useContext, createContext, useMemo } from 'react'
import { AxiosResponse } from 'axios'
import { type } from 'os'
import Authentication from '../../utils/authentication'
import CapabilityAPI from '../../apis/capability.api'

interface IAuthContext {
  isAuthed: boolean
  isOrgAdmin: boolean
  orgId: number | null
  isFirstRender: boolean
  loggedInUser: UserInfo | undefined
  checkAuth(): any
  capabilitiesByCategory: Record<string, string[]>
}

export type UserInfo = {
  userId: number
  userName: string
  fullName: string
}

const authContext = createContext({} as IAuthContext)

const useProvideAuth = () => {
  const [isAuthed, setAuthed] = useState(false)
  const [isOrgAdmin, setOrgAdmin] = useState(false)
  const [orgId, setOrgId] = useState<number | null>(null)
  const [isFirstRender, setFirstRender] = useState(true)
  const [user, setUser] = useState<UserInfo>()
  const [error, setError] = useState()
  const [capabilitiesByCategory, setCapabilitiesByCategory] = useState<Record<string, string[]>>({})

  const initializeCapabilities = async () => {
    const [capabilitiesPromise, categoriesPromise] = await Promise.all([
      CapabilityAPI.getAllCapabilities(),
      CapabilityAPI.getAllCapabilityCategories(),
    ])
    setCapabilitiesByCategory(
      CapabilityAPI.parseCapabilitiesByCategory(categoriesPromise.data, capabilitiesPromise.data)
    )
  }

  useMemo(() => {
    initializeCapabilities()
  }, [])

  const checkAuth = async () => {
    try {
      const { userInfo, token } = await Authentication.getUMToken()
      setAuthed(true)
      setUser({
        userId: token.userId,
        userName: userInfo.userName,
        fullName: userInfo.fullName,
      })
      setOrgAdmin(token.capabilities.includes('ORGADMIN'))
      setOrgId(token.orgId)
      setFirstRender(false)
      return { userInfo, token }
    } catch (err) {
      setFirstRender(false)
      return Promise.reject(err)
    }
  }

  useEffect(() => {
    checkAuth().catch(err => {
      setError(err)
    })

    // event listener to listen to local starage change event
    window.addEventListener('storage', localStorageUpdated)

    // cleanup callback function
    return () => {
      window.removeEventListener('storage', localStorageUpdated)
    }
  }, [])

  // method to handle /login redirection on multi tab logout at once
  const localStorageUpdated = () => {
    // set authentication to false if UM token and OIDC access token are not present in the local storage
    if (!localStorage.getItem('UMToken') && !localStorage.getItem('accessToken')) {
      setAuthed(false)
      setFirstRender(false)
    }
  }

  return {
    isAuthed,
    isOrgAdmin,
    orgId,
    isFirstRender,
    loggedInUser: user,
    checkAuth,
    capabilitiesByCategory,
  }
}

export const ProvideAuth = ({ children }: any) => {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext)
}
