import React from 'react'
import { Layout, Divider, Typography, Tooltip, Space } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import logo from '../img/logo.svg'
import { useAuth, useNavigation } from './hooks'
import Authentication from '../utils/authentication'

const { Header } = Layout
const { Title } = Typography

const Nav = () => {
  const { loggedInUser } = useAuth()
  const { toOrganization } = useNavigation()

  return (
    <Header
      css={{
        backgroundColor: '#fff',
        border: 'solid rgba(34,36,38,.15)',
        borderWidth: '0 0 1px',
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <section
        role="button"
        tabIndex={-1}
        className="logo"
        style={{ display: 'inline-block', cursor: 'pointer' }}
        onClick={() => toOrganization()}
      >
        <Space>
          <img src={logo} css={{ height: '32px' }} alt="Chainalysis" />
          <Title level={3} style={{ verticalAlign: 'middle', display: 'inline' }}>
            Admin Portal
          </Title>
        </Space>
      </section>
      <section style={{ display: 'inline-block', float: 'right' }}>
        {loggedInUser ? (
          <>
            <Tooltip
              title={
                <div>
                  <section>Full name: {loggedInUser.fullName}</section>
                  <section>User name: {loggedInUser.userName}</section>
                </div>
              }
            >
              <Typography.Text>{`Welcome ${loggedInUser.userName}`}</Typography.Text>
            </Tooltip>
            <Divider type="vertical" />
          </>
        ) : null}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <span role="button" tabIndex={-2} onClick={() => Authentication.logout()} style={{ cursor: 'pointer' }}>
          <LogoutOutlined /> Sign out
        </span>
      </section>
    </Header>
  )
}

export default Nav
