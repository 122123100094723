import { WebAuth } from 'auth0-js'

class Auth0OIDCAuthentication {
  private domain: string
  private clientID: string
  private redirectUrl: string
  private apiIdentifier: string
  private webAuth: WebAuth

  constructor() {
    this.domain = process.env.REACT_APP_OIDC_DOMAIN || ''
    this.clientID = process.env.REACT_APP_OIDC_CLIENT_ID || ''
    this.redirectUrl = window.location.origin + process.env.REACT_APP_OIDC_REDIRECT_PATH || ''
    this.apiIdentifier = process.env.REACT_APP_OIDC_MGMT_API_IDENTIFIER || ''
    this.webAuth = new WebAuth({
      domain: this.domain,
      clientID: this.clientID,
    })
  }

  public login(connection: string): void {
    this.webAuth.authorize({
      connection,
      responseType: 'code',
      scope: 'openid email offline_access',
      audience: this.apiIdentifier,
      redirectUri: this.redirectUrl,
    })
  }

  public logout(returnTo?: string): void {
    this.webAuth.logout({
      returnTo,
      clientID: this.clientID,
    })
  }
}

export default new Auth0OIDCAuthentication()
