import { AxiosResponse } from 'axios'
import { makeUMRequest } from '../utils/request'
import { ICreateBulkUserRequest, IUpdateUserRequest, ISetUserPropertiesRequest } from '../utils/types'

class UserAPI {
  static async getAllUsers(orgId: number | null, params: any): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'GET',
      url: `orgadmin/organization/${orgId}/users`,
      params,
    })
    return res.data
  }

  static async getUser(orgId: number, userId: number): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'GET',
      url: `orgadmin/organization/${orgId}/user/${userId}`,
    })
    return res.data
  }

  static async createUsersInBulk(orgId: number | null, data: ICreateBulkUserRequest): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'POST',
      url: `orgadmin/users`,
      data,
    })
    return res.data
  }

  static async updateUser(orgId: number, userId: number, data: IUpdateUserRequest): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'PUT',
      url: `orgadmin/organization/${orgId}/user/${userId}`,
      data,
    })
    return res.data
  }

  static async setUserProperties(orgId: number, userId: number, data: ISetUserPropertiesRequest): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'POST',
      url: `orgadmin/organization/${orgId}/user/${userId}/properties`,
      data,
    })
    return res.data
  }

  static async setUserExpiry(orgId: number, userId: number, expiry: number): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'POST',
      url: `orgadmin/organization/${orgId}/user/${userId}/expireAt`,
      params: {
        expiry,
      },
    })
    return res.data
  }

  static async resetPassword(orgId: number, userId: number): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'POST',
      url: `orgadmin/organization/${orgId}/user/${userId}/password-reset`,
      data: {
        productName: 'selfServiceAdmin',
      },
    })
    return res.data
  }
}

export default UserAPI
