import { useLocation } from 'react-router-dom'

const useQueryParams = (): any => {
  const location = useLocation()
  const searchParams: any = location.search ? new URLSearchParams(location.search) : new URLSearchParams(location.hash)
  /* eslint-disable no-restricted-syntax */
  const obj: any = {}
  for (const [key, value] of searchParams.entries()) {
    obj[key] = value === 'true' || value === 'false' ? JSON.parse(value) : value
  }
  return obj
}

export default useQueryParams
