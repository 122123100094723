import AuthenticationAPI from '../apis/authentication.api'
import auth0OidcAuthentication from './auth0-oidc-authentication'
import { IAccessTokenResponse, IAuth0LoginResponse, ILoginResponse, IUMTokenResponse, IUserInfoResponse } from './types'
import { parseLocalStorageItem } from '.'

class Authentication {
  static async login(user: string, password: string) {
    const res: ILoginResponse = await AuthenticationAPI.login({ user, password })
    this.clearUserSessionInfoStorage()
    localStorage.setItem('isIDPLogin', 'false')
    localStorage.setItem('csrfToken', res.token)
    return res
  }

  static async auth0Login(authorizationCode: string, idToken: string) {
    const res: IAuth0LoginResponse = await AuthenticationAPI.authOLogin({ authorizationCode, idToken })
    this.clearUserSessionInfoStorage()
    localStorage.setItem('isIDPLoginViaSAML', 'true')
    localStorage.setItem('csrfToken', res.token)
    return res
  }

  static async getUMToken() {
    const userInfo: IUserInfoResponse = await AuthenticationAPI.getUserInfo()
    localStorage.setItem('csrfToken', userInfo.csrfToken)
    if (userInfo.organizations[0].idpUrl) {
      localStorage.setItem('idpUrl', userInfo.organizations[0].idpUrl)
    }
    const orgId = userInfo.organizations[0].id
    const token: IUMTokenResponse = await AuthenticationAPI.getToken({ orgId, productName: 'Reactor' })
    localStorage.setItem('UMToken', token.token)
    return { userInfo, token }
  }

  static async oidcLogin(authorizationCode: string, state: string, redirectUrl: string) {
    const token: IAccessTokenResponse = await AuthenticationAPI.getAccessToken({
      authorization_code: authorizationCode,
      state,
      redirectUrl,
    })
    this.clearUserSessionInfoStorage()
    localStorage.setItem('isIDPLogin', 'true')
    localStorage.setItem('csrfToken', token.token)
    localStorage.setItem('accessToken', token.accessToken)
    localStorage.setItem('refreshToken', token.refreshToken)
    return token
  }

  static async refreshOIDCAccessToken(refreshToken: string | null) {
    const token: IAccessTokenResponse = await AuthenticationAPI.refreshAccessToken({ refresh_token: refreshToken })
    localStorage.setItem('accessToken', token.accessToken)
    localStorage.setItem('refreshToken', token.refreshToken)
    return token
  }

  static logout() {
    AuthenticationAPI.logout().finally(() => {
      const idpUrl = localStorage.getItem('idpUrl')
      const isIDPLogin = parseLocalStorageItem('isIDPLogin')
      const isIDPLoginViaSAML = parseLocalStorageItem('isIDPLoginViaSAML')

      this.clearUserSessionInfoStorage()

      if (isIDPLogin || isIDPLoginViaSAML) {
        const returnTo =
          isIDPLoginViaSAML && idpUrl ? idpUrl : window.location.origin + process.env.REACT_APP_OIDC_REDIRECT_PATH
        auth0OidcAuthentication.logout(returnTo)
      } else {
        window.location.href = process.env.REACT_APP_LOGIN_URL || '/login'
      }
    })
  }

  private static clearUserSessionInfoStorage() {
    localStorage.removeItem('isIDPLogin')
    localStorage.removeItem('isIDPLoginViaSAML')
    localStorage.removeItem('idpUrl')
    localStorage.removeItem('csrfToken')
    localStorage.removeItem('UMToken')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
  }
}

export default Authentication
