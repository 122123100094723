/**
 * Returns true if the active user is the selected user
 * @param userId the selected user
 * @returns boolean indicating if the active user and selected user are equal
 */
const checkIsCurrentUser = (activeUserId: number, userId: number | undefined): boolean => {
  if (activeUserId && userId) {
    return activeUserId === userId
  }
  return false
}

export default checkIsCurrentUser
