import React from 'react'
import { Layout, Row, Col } from 'antd'
/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import Nav from './Nav'

const { Content } = Layout

type AppContainerProps = {
  key?: string
  isSider?: boolean
  anchor?: boolean
  sidebar?: any
  children?: any
}

const AppContainer = ({ children, isSider, key, sidebar, anchor }: AppContainerProps) => (
  <Layout className="layout" key={key}>
    <Nav />
    <Content style={{ padding: '20px 50px', minHeight: '100vh' }}>
      <div css={{ margin: 'auto', maxWidth: '1750px' }}>{children}</div>
    </Content>
    {sidebar}
  </Layout>
)

export default AppContainer
