import React from 'react'
import { Result } from 'antd'

const Unauthorized: React.FC = () => {
  return (
    <Result
      status="403"
      title="Access Denied"
      subTitle={
        <p>
          Sorry, you don&apos;t have access to Admin Portal. For more information, contact&nbsp;
          <a href="mailto:support@chainalysis.com">support@chainalysis.com</a>.
        </p>
      }
    />
  )
}

export default Unauthorized
