import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Input, Button, Layout, Form, Space } from 'antd'
/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import logo from '../img/chainalysis-logo.svg'
import { AuthenticationAPI } from '../apis'
import { ErrorMessage } from './styled/text'
import { LoginPagePattern } from './styled'

const { Content } = Layout

const ForgotPassword = () => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [message, setMessage] = useState('')

  const handleFormSubmit = (formValues: any) => {
    AuthenticationAPI.requestPasswordReset(formValues.email)
      .then(() => {
        setIsSuccess(true)
        setMessage('You will shortly receive an email with instructions to reset your password.')
      })
      .catch(() => {
        setIsError(true)
        setMessage('Something went wrong. Please try again later.')
      })
  }

  return (
    <Layout style={{ backgroundColor: '#fff' }}>
      <Content
        style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          height: '100%',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <LoginPagePattern />
        <div css={{ flex: '1 1 61.8%', padding: '4rem', display: 'flex', flexDirection: 'column' }}>
          <div>
            <img src={logo} alt="Chainalysis" />
          </div>
          <div>
            <h2>Forgot Password?</h2>
            {isSuccess ? (
              <div>
                <p>{message}</p>
                <Button type="primary" size="large">
                  <Link to="/login">Back to Sign In</Link>
                </Button>
              </div>
            ) : (
              <div>
                {isError && <ErrorMessage>{message}</ErrorMessage>}
                <Form layout="vertical" onFinish={handleFormSubmit}>
                  <Form.Item
                    name="email"
                    label="Email address"
                    rules={[{ required: true, message: 'Please enter your email' }]}
                  >
                    <Input size="large" placeholder="Please enter your email to request a password reset" />
                  </Form.Item>
                  <Form.Item>
                    <Space>
                      <Button type="primary" size="large" htmlType="button">
                        <Link to="/login">Back to Sign In</Link>
                      </Button>
                      <Button type="primary" size="large" htmlType="submit">
                        Reset Password
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              </div>
            )}
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default ForgotPassword
