const parseLocalStorageItem = (item: string): any => {
  try {
    const str = localStorage.getItem(item)
    return str ? JSON.parse(str) : null
  } catch (err) {
    return null
  }
}

export default parseLocalStorageItem
