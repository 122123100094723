import { AxiosPromise, AxiosResponse } from 'axios'
import { makeUMRequest } from '../utils/request'
import { CapabilityCategoryResponse, CapabilityMinResponse, CategoryRecordKeys } from '../utils/types'

class CapabilityAPI {
  static async getAllCapabilityCategories(): Promise<any> {
    const res: AxiosResponse<CapabilityCategoryResponse[]> = await makeUMRequest({
      method: 'GET',
      url: 'capability/category',
    })
    return res
  }
  static async getAllCapabilities(): Promise<any> {
    const res: AxiosResponse<CapabilityMinResponse[]> = await makeUMRequest({
      method: 'GET',
      url: 'capability',
    })
    return res
  }

  static parseCapabilitiesByCategory = (
    capabilityCategoryList: CapabilityCategoryResponse[],
    capabilities: CapabilityMinResponse[]
  ): Record<string, string[]> => {
    const res: Record<string, string[]> = {}
    const categoryIdRecord: Record<number, string> = {}

    capabilityCategoryList.sort((cat1, cat2) => cat1.categoryOrder - cat2.categoryOrder)
    capabilityCategoryList.forEach(category => {
      res[category.displayLabel] = []
      categoryIdRecord[category.id] = category.displayLabel
    })

    capabilities.forEach(capabilityState => {
      const currCategory = categoryIdRecord[capabilityState.categoryId]
      const prevList = res[currCategory]
      if (prevList) {
        res[currCategory] = prevList.concat(capabilityState.identifier)
      }
    })

    return res
  }
}

export default CapabilityAPI
